import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import Tags from '@widgets/Tags'
import Social from '@widgets/Social'
import { useStaticQuery, graphql } from 'gatsby'


const paginatedPostsQuery = graphql `
query allArticle{
  paginatedPosts: allArticle(
    filter: { private: { ne: true } }
    sort: { fields: [date], order: DESC }
  ) @include(if: true) {
    nodes  {
      ...ArticlePreview
      ...ArticleThumbnailRegular
    }
    ...ArticlePagination
  }
}
`

const Articles = () => {
  const  data  = useStaticQuery(paginatedPostsQuery)
  // const { pageContext: { services = {} } = {} } = props
  return (
    <>
      <Seo title='Articles' />
      <Divider />
      <Stack>
        <Main>
          <CardList
            variant={['horizontal-md', 'horizontal']}
            title='Articles'
            nodes={data.paginatedPosts.nodes}
            columns={[1]}
          />
        </Main>
        <Sidebar>
          <Categories />
          <Divider />
          <Tags />
          <Divider />
          <Social />
        </Sidebar>
      </Stack>
      <Divider />
    </>
  )
}


export default Articles
